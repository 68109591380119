<template>
  <div>
    <div
      class="recommendBox flex flex-center rel"
      v-for="(item, index) in [0, 1, 2, 3, 4]"
      :key="index"
    >
      <img
        class="recommendBox-share abs"
        src="../assets/imgs/share.png"
        alt=""
      />
      <img class="recommend-img" src="../assets/imgs/homeBg.png" alt="" />
      <div>
        <div class="recommend-title">
          活动标题活动标12312312321321321312321321312题
        </div>
        <div class="recommend-content">
          简介简介简介简介简介简介简介 简介简介简介sASasA简介简介简介简介
        </div>
        <div class="flex flex-center">
          <div
            class="recommend-item"
            v-for="(val, idx) in [1, 2, 3]"
            :key="idx"
          >
            健身
          </div>
        </div>
      </div>
    </div>

    <div class="padding-b"></div>
    <tab :current="2" :isShow="6"></tab>
  </div>
</template>

<script>
import tab from "@/components/tab.vue";

export default {
  components: { tab },
  data() {
    return {};
  },
};
</script>

<style scoped>
.padding-b {
  height: 200px;
}

.recommendBox {
  width: 690px;
  height: 245px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 24px auto;
}

.recommend-img {
  width: 185px;
  height: 185px;
  border-radius: 16px;
  margin: 0 20px 0 30px;
}

.recommend-title {
  width: 336px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recommend-content {
  width: 336px;
  height: 66px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
  margin: 14px 0 25px 0;
}

.recommend-item {
  padding: 2px 21px;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  background: #edf3ff;
  margin-right: 10px;
}

.recommendBox-share {
  width: 60px;
  height: 60px;
  right: 30px;
  bottom: 30px;
}
</style>
